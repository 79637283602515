<template>
  <base-section id="404">
    <base-heading
      :title="$t('sections.404.title')"
      class="primary--text"
    />

    <base-subheading
      class="primary--text"
      :title="$t('sections.404.subheading1')"
    />

    <base-subheading
      space="8"
      :title="$t('sections.404.subheading2')"
    />

    <base-img
      :src="require('@/assets/paydone/check-dark.png')"
      class="mx-auto mb-12"
      contain
      height="100"
      width="250"
    />

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        {{ $t("sections.404.btn") }}
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
